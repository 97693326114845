import { Dialog } from 'vant'

const ua = navigator.userAgent
let logMsg: string[] = []

// 页面地址
const locationInfo = () => {
	return location.href
}

//  UA
const UAInfo = () => {
	return ua
}

// 网络类型
const networkInfo = () => {
	let network = ua.toLowerCase().match(/ nettype\/([^ ]+)/g)
	if (network && network[0]) {
		network = network[0].split('/')
		logMsg = [network[1]]
		console.info('[system]', 'Network:', logMsg.length ? logMsg.join(', ') : 'Unknown')
	}
	return 'Network:' + logMsg.length ? logMsg.join(', ') : 'Unknown'
}

// 设备 & 系统
const clientInfo = () => {
	// 微信客户端版本
	const wxVersionMatch = ua.match(/MicroMessenger\/([\d\.]+)/i)
	const wxVersion = wxVersionMatch && wxVersionMatch[1] ? wxVersionMatch[1] : null
	// 设备 & 系统
	const ipod = ua.match(/(ipod).*\s([\d_]+)/i),
		ipad = ua.match(/(ipad).*\s([\d_]+)/i),
		iphone = ua.match(/(iphone)\sos\s([\d_]+)/i),
		android = ua.match(/(android)\s([\d\.]+)/i),
		mac = ua.match(/(Mac OS X)\s([\d_]+)/i)

	logMsg = []
	if (android) {
		logMsg.push('Android ' + android[2])
	} else if (iphone) {
		logMsg.push('iPhone, iOS ' + iphone[2].replace(/_/g, '.'))
	} else if (ipad) {
		logMsg.push('iPad, iOS ' + ipad[2].replace(/_/g, '.'))
	} else if (ipod) {
		logMsg.push('iPod, iOS ' + ipod[2].replace(/_/g, '.'))
	} else if (mac) {
		logMsg.push('Mac, MacOS ' + mac[2].replace(/_/g, '.'))
	}
	if (wxVersion) {
		logMsg.push('WeChat ' + wxVersion)
	}

	return logMsg.length ? logMsg.join(', ') : 'Unknown'
}
export const deviceInfo = () => {
	Dialog({
		message: () => (
			<div style="font-size: 10px;text-align: left;">
				<div>Location：{locationInfo()}</div>
				<div>Client：{clientInfo()}</div>
				<div>Network：{networkInfo()}</div>
				<div>UA：{UAInfo()}</div>
			</div>
		),
	})
}
