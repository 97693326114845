//文档: https://github.com/stipsan/smooth-scroll-into-view-if-needed
import scrollIntoView from 'smooth-scroll-into-view-if-needed'
import { DirectiveBinding, RendererElement, VNode } from 'vue'

const lookat = {
	beforeMount(el: RendererElement, binding: DirectiveBinding, vnode: VNode) {
		const dir = binding.dir as anyObject
		dir.moveIntoView = () => {
			const element = document.querySelector(binding.value) as HTMLElement
			scrollIntoView(element, {
				block: 'start',
				behavior: 'smooth',
				scrollMode: 'if-needed',
			})
		}
		el.addEventListener('click', dir.moveIntoView)
	},
	beforeUnmount(el: RendererElement, binding: DirectiveBinding) {
		const dir = binding.dir as anyObject
		el.removeEventListener('click', dir.moveIntoView)
	},
}
export default lookat
