import { DirectiveBinding, RendererElement, VNode } from 'vue'
//  工具方法
export const titleCase = (str: string) => str.charAt(0).toUpperCase() + str.substring(1).toLowerCase()

const debounceFn = (fn: () => void, delay: number) => {
	let timer: number
	return () => {
		clearTimeout(timer)
		timer = setTimeout(() => {
			fn()
		}, delay)
	}
}

const debounce = {
	created(el: RendererElement, binding: DirectiveBinding, vnode: VNode) {
		const props = vnode.props as anyObject
		const values = (binding.value || '').split('|')
		values.forEach((value: string) => {
			const [name, delay] = value.split(':')
			const eventName = 'on' + titleCase(name)
			if (props[eventName]) {
				props[eventName] = debounceFn(props[eventName], Number(delay || 1000))
			}
		})
		console.log(el, binding, vnode)
	},
	mounted(el: RendererElement, binding: DirectiveBinding, vnode: VNode) {
		//console.log(el, binding, vnode)
	},
}

export default debounce
