import { createI18n } from 'vue-i18n'

// 语言库
const messages = {
	en: require('./@en').default,
	zh: require('./@zh').default,
}
//const messages = {
//	en: {
//		message: {
//			hello: 'hello world',
//		},
//	},
//	zh: {
//		message: {
//			hello: 'こんにちは、世界',
//		},
//	},
//}
// 默认语言
const langDefault = 'en'

// 将i18n暴露出去，在main.js中引入挂载
export const i18n = createI18n({
	legacy: false,
	locale: langDefault, //默认显示的语言
	messages,
})
