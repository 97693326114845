import { Dialog } from 'vant'

// 参考地址：https://developer.mozilla.org/zh-CN/docs/Glossary/First_paint
// 白屏时间
const getBlankTime = () => {
	const paintTime = performance.getEntriesByType('paint')
	return (paintTime[0].startTime + paintTime[1].startTime) / 2
}

// 首屏时间
const getFirstScreenTime = () => {
	return (performance.getEntries()[0] as anyObject).domContentLoadedEventStart
}

// 总下载时间
const getTotalDownloadTime = () => {
	return (performance.getEntries()[0] as anyObject).loadEventEnd
}

// 总完成时间
const getTotalFinishTime = () => {
	const all = performance.getEntries()
	const list = []
	for (const iterator of all) {
		list.push((iterator as anyObject).responseEnd)
	}
	list.sort((a, b) => {
		return b - a
	})
	return list[0]
}
export const firstContentfulPaint = () => {
	Dialog({
		message: () => (
			<div style="font-size: 12px;">
				<div>白屏时间：{(getBlankTime() + '').split('.')[0]}ms</div>
				<div>首屏时间：{(getFirstScreenTime() + '').split('.')[0]}ms</div>
				<div>总下载时间：{(getTotalDownloadTime() + '').split('.')[0]}ms</div>
				<div>总完成时间：{(getTotalFinishTime() + '').split('.')[0]}ms</div>
			</div>
		),
	})
}
