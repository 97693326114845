//  持久化引擎目前支持的类型:
//  String, Number, Boolean, Symbol, Bigint, Null, Undefined,
//  Array, Object, Date, Set, Map, RegExp, Function,
//TODO:   WeakSet, WeakMap, Blob, File, Error,  后续补上
import { serialize, deserialize } from '@core/tools/json-serialize'
//  初始化全局数据控制器
import { createPinia, Pinia, defineStore as DS } from 'pinia'

export const defineStore = DS

let store: Pinia
export const createStore = () => {
	store = createPinia()
	registerStorePlugin()
	return store
}

//  添加持久化数据插件
//  文档:https://github.com/prazdevs/pinia-plugin-persistedstate
import { createPersistedState } from 'pinia-plugin-persistedstate'
const registerStorePlugin = () => {
	store.use(
		createPersistedState({
			storage: localStorage,
			afterRestore: (context) => {
				const { id } = context.options as anyObject
				//if (context.store.init) context.store.init()
				console.log(`模块 [globalStore.${id}] 已从本地恢复状态...`)
			},
			serializer: {
				serialize: serialize,
				deserialize: deserialize,
			},
		}),
	)
}

//  数据中心化管理,避免多次创建,提高性能,并且防止多次打包
export const globalStore: anyObject = {}

//  在main.ts中引入,并执行registerStore()
//  需要注册的数据模块,必须在此处注册,否则无法使用
let storeMoudleQueue: Set<{ name: string; install: () => void }>
export const addStoreModule = (_globalStore: anyArray) => {
	storeMoudleQueue = new Set(_globalStore)
}

export const registerStoreModule = () => {
	;[...storeMoudleQueue].forEach((item: anyObject) => {
		globalStore[item.name] = item.install()
	})
}
