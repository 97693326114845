import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, Canceler } from 'axios'

export let proxyUrl = ''
export const setProxyUrl = (url: string) => {
	proxyUrl = url
}

//  代理原始数据类型  String  Number  Boolean, 用于辅助检测出参数据类型
export const String = new Proxy(window.String, {
	apply(...agrs) {
		if (typeof agrs[2][0] === 'string') return new window.String(agrs[2])
		else {
			throw new TypeError(`[String断言方法]请传入一个字符值,当前值为${agrs[2][0]}类型为${typeof agrs[2][0]}`)
		}
	},
})
export const Number = new Proxy(window.Number, {
	apply(...agrs) {
		if (typeof agrs[2][0] === 'number') return new window.Number(agrs[2])
		else {
			throw new TypeError(`[Number断言方法]请传入一个数字值,当前值为${agrs[2][0]}类型为${typeof agrs[2][0]}`)
		}
	},
})
export const Boolean = new Proxy(window.Boolean, {
	apply(...agrs) {
		if (typeof agrs[2][0] === 'boolean') return new window.Boolean(agrs[2])
		else {
			throw new TypeError(`[Boolean断言方法]请传入一个布尔值,当前值为${agrs[2][0]}类型为${typeof agrs[2][0]}`)
		}
	},
})

//  判断预期的数据类型是否和实际返回类型一致  如果不一致就抛出false 否则返回true
//  如果预期的数据类型是对象或数组，那么实际返回的数据类型也必须是对象或数组  并且会遍历对象或数组的属性
//  目前支持的类型有：Object, Array, String, Number, Boolean
type paramsType = anyObject | anyArray | number | string | boolean
//  interceptorsResponse拦截器回调的数据  expectData预期的数据  responseData响应的数据
export const checkResponseDataType = (interceptorsResponse: anyObject, expectData: paramsType, responseData: paramsType) => {
	try {
		// 这个方法只对响应体是对象或数组校验
		// 数字,字符串,布尔值 在checkResponseBody里已经校验过了,所以这里不需要再校验
		if (!/Object|Array/.test(toString.call(responseData))) return false

		//  如果预期是数组,并且每条数据类型都一样,则判断为列表类型数据
		//  列表类型数据无论预期需要多少条数据,都只会判断第一条的数据类型,并且响应数据条数,不能超过预期条数
		//  例如:预期需要返回10条数据,响应数据不能超过10条,但是可以少于10条,因为最后一页可能不足10条
		if (/Array/.test(toString.call(expectData))) {
			expectData = expectData as anyArray
			let uniformity = false // 数据统一性
			if (expectData.length > 1) {
				expectData.reduce((prev: paramsType, current: paramsType) => {
					if (JSON.stringify(prev) === JSON.stringify(current)) {
						uniformity = true
						return current
					} else {
						uniformity = false
						return Symbol()
					}
				})
			} else {
				uniformity = true
			}
			if (uniformity) {
				//  响应数据是数组,如果响应数据条数大于预期数据条数,则不符合预期
				if (/Array/.test(toString.call(responseData))) {
					responseData = responseData as anyArray
					if (responseData.length > expectData.length) throw `数据条数不符合预期: 预期${expectData.length}条 实际${responseData.length}条`
				} else {
					expectData.length = 1
				}
			}
		}

		//  这里的key,对象返回的是键,数组返回的是索引
		for (const key of Object.keys(expectData)) {
			expectData = expectData as anyObject
			responseData = responseData as anyObject
			//  固定数据是指和服务端约定好不会改变的数据,比如约定好是一个'aaa'字符串,那么实际返回的数据也必须是一个'aaa'字符串
			if (typeof expectData[key] == 'object' && /Number|String|Boolean/.test(toString.call(expectData[key]))) {
				const constValue = expectData[key].valueOf()
				if (constValue == responseData[key]) {
					continue
				} else {
					throw `常量数据 ${key}属性值不符: 预期类型${toString.call(expectData[key])} 预期值${expectData[key]}   实际类型${toString.call(responseData[key])} 实际值${responseData[key]}`
				}
			}
			//递归  Object, Array
			const expectDataToString = toString.call(expectData[key])
			const responseDataToString = toString.call(responseData[key])
			if (/Object|Array/.test(expectDataToString)) {
				if (expectDataToString == responseDataToString) {
					if (checkResponseDataType(interceptorsResponse, expectData[key], responseData[key])) return true
					else {
						continue
					}
				} else {
					throw `${key}属性缺失: 预期类型${toString.call(expectData[key])}   实际类型${toString.call(responseData[key])}`
				}
			}

			//判断  String, Number, Boolean
			const responseDataType = typeof responseData[key]
			const expectDataType = expectData[key].name.toLowerCase()
			if (expectDataType == 'number' || expectDataType == 'string' || expectDataType == 'boolean') {
				if (expectDataType == responseDataType) continue
				else throw `${key}属性缺失: 预期类型${expectDataType}    实际类型${toString.call(responseData[key])}`
			}
		}
		return false
	} catch (err) {
		console.error({
			baseURL: interceptorsResponse.config.baseURL,
			url: interceptorsResponse.config.url,
			inParams: interceptorsResponse.config.config.inParams,
			outParamsType: interceptorsResponse.config.config.outParamsType,
			errMsg: err,
		})
		return true
	}
}
//: { code: number; message: string; data: paramsType }
// 校验响应体是否符合规范
export const checkResponseBody = (axiosResponse: anyObject, expectData: paramsType, responseBody: anyObject) => {
	try {
		// 响应体需要是一个对象
		if (/Object/.test(toString.call(responseBody))) {
			const { code, message, data } = responseBody

			//  响应体里只有data可以设置固定数据
			//  固定数据是指和服务端约定好不会改变的数据,比如约定好是一个'aaa'字符串,那么实际返回的数据也必须是一个'aaa'字符串
			const typeName = /Number|String|Boolean/.exec(toString.call(expectData))
			if (typeName) {
				if (typeof expectData == 'object') {
					if (expectData.valueOf() !== data) {
						throw `data属性是常量数据与预期不符:  预期类型${toString.call(expectData)} 预期值${expectData.valueOf()}  实际类型${toString.call(data)} 实际值${data}`
					}
				} else {
					if (typeName[0] === 'String') {
						throw `常量数据书写格式错误!  请改成:'${expectData}' -> ${typeName[0]}('${expectData}')`
					} else {
						throw `常量数据书写格式错误!  请改成:${expectData} -> ${typeName[0]}(${expectData})`
					}
				}
			}
			// 响应体需要有code,message,data三个属性
			if (typeof code !== 'number') throw `code属性类型不符: 预期number  实际${toString.call(code)}`
			//if (typeof message !== 'string') throw `message属性类型不符: 预期string  实际${toString.call(message)}`
			if (!/Object|Array|Number|String|Boolean/.test(Object.name)) throw `data属性类型不符: 预期Object|Array|Number|String|Boolean  实际${toString.call(data)}}`
		} else throw `responseBody响应体类型不符: 预期Object  实际${toString.call(responseBody)}`
		return false
	} catch (err) {
		console.error({
			baseURL: axiosResponse?.config.baseURL,
			url: axiosResponse?.config.url,
			responseBody: axiosResponse.data,
			errMsg: err,
		})
		return true
	}
}

// 定义一个请求集合，用于集中管理当前正在进行的请求,如果当前请求已经存在，那么就不再重复请求
const requestQueue = (() => {
	const queue: anyObject = {}
	return {
		cacheData(response: anyObject, second: number) {
			console.log('secondsecond', second)
			const { config, data } = response
			const keyName: string = JSON.stringify({ baseURL: config.baseURL, url: config.url, data: config.config.inParams })
			//  时间到期自动清除缓存
			const timer = setTimeout(() => {
				delete queue[keyName]
			}, second * 1000)
			queue[keyName] = { tips: '已读取缓存在内存的数据', cancelReason: 'READ_CACHE', data, timer }
		},
		set(config: anyObject) {
			const keyName: string = JSON.stringify({ baseURL: config.baseURL, url: config.url, data: config.config.inParams })
			queue[keyName] = { tips: '已有相同参数的接口正在请求中', cancelReason: 'REPEAT_REQUEST' }
		},
		get(config: anyObject) {
			const keyName: string = JSON.stringify({ baseURL: config.baseURL, url: config.url, data: config.config.inParams })
			return queue[keyName]
		},
		remove(config: anyObject) {
			const keyName: string = JSON.stringify({ baseURL: config.baseURL, url: config.url, data: config.config.inParams })
			clearTimeout(queue[keyName].timer)
			delete queue[keyName]
		},
	}
})()

let service: AxiosInstance
//  ======================================================
export const createPost = (_config: AxiosRequestConfig<never> | anyObject = {}) => {
	// 创建axios实例
	service = axios.create(_config)

	// 添加请求拦截器
	service.interceptors.request.use(
		function (config: anyObject) {
			const { baseURL, url, data } = config
			config.baseURL
			console.log('configconfigconfig', config)
			config.cancelToken = new axios.CancelToken(function executor(cancel) {
				//  requestQueue.get(config) 判断相同的请求是否在最队列中,如果在最队列中,那么就取消请求
				if (requestQueue.get(config)) {
					//  config.config.hardReload 硬刷新为true会绕过缓存,重新从服务器请求数据
					if (config.config.hardReload) {
						requestQueue.remove(config)
						requestQueue.set(config)
					} else {
						//  如果有缓存,会通过message属性传递缓存数据
						//  因为cancel函数接收的参数只能传递到错误状态的message属性中
						cancel(requestQueue.get(config))
					}
				} else {
					requestQueue.set(config)
				}
			})
			//  循环进入拦截器队列
			config = beforeRequestCallBack(config)
			return config
		},
		function (error) {
			return Promise.reject(error)
		},
	)

	// 添加响应拦截器
	service.interceptors.response.use(
		function (axiosResponse: AxiosResponse) {
			axiosResponse = beforeResponseCallBack(axiosResponse) as AxiosResponse
			const configBody = (axiosResponse.config as anyObject).requestBody || {}
			if (configBody.data) {
				axiosResponse.data.data = axiosResponse.data[configBody.data]
			}
			if (configBody.code) {
				axiosResponse.data.code = axiosResponse.data[configBody.code]
			}
			if (configBody.message) {
				axiosResponse.data.message = axiosResponse.data[configBody.message]
			}
			const { data, code, message } = axiosResponse.data
			const config: anyObject = axiosResponse.config
			//  校验接口响应体是否符合规范
			if (checkResponseBody(axiosResponse, config.config.outParamsType, axiosResponse.data)) {
				return Promise.reject({ message: '接口响应体类型不符' })
			}

			switch (code) {
				case 200:
					////  如果状态为200,表示服务端认为自己响应的数据是正确的,尽管如此我也要校验下接口响应数据是否符合预期
					//if (checkResponseDataType(axiosResponse, config.config.outParamsType, data)) {
					//	return Promise.reject({ message: '接口响应数据类型不符' })
					//}
					//  如果上面检测没有抛错,那么就把数据缓存起来
					requestQueue.cacheData(axiosResponse, config.config.cacheTime)
					console.log('成功请求')
					break
				case 403:
					console.log('没有访问权限')
					break
				case 530:
					console.log('未登录')
					break
				case 999:
					console.log('接口出参基本格式不正确')
					break
				default:
					console.log('未知错误')
					break
			}
			return Promise.resolve(axiosResponse.data)
		},
		function (error) {
			console.log('error', error)
			switch (error.code) {
				case 'ERR_BAD_REQUEST':
					console.warn('4xx客户端错误')
					break
				case 'ERR_BAD_RESPONSE':
					console.warn('5xx服务端错误')
					break
				case 'ERR_NETWORK':
					console.warn('网路错误,请检查你的网络,也有可能是服务端的端口没启动')
					break
				case 'ECONNABORTED':
					console.warn(`服务端${service.defaults.timeout}秒未响应,响应超时,已断开连接`)
					break
				case 'ERR_CANCELED': //  取消请求
					console.log('本次请求已取消')
					break
				default:
					// 状态码为3xx时,会缓存请求,建议清空数据再强制刷新,来获得最新数据
					if (error.response?.status.toString()[0] == '3') {
						console.warn('状态码为3xx时,会缓存请求,建议清空数据再强制刷新,来获得最新数据')
					} else {
						console.warn('未知错误')
					}
					break
			}
			//  请求接口被取消,message属性里有缓存数据,并且是因为要读取缓存数据而被取消的
			console.log('error.message', error.message)
			if (error.code === 'ERR_CANCELED' && /Object/.test(error.message) && error.message.cancelReason === 'READ_CACHE') {
				//  满足以上条件,返回成功状态
				const cacheData = error.message
				return Promise.resolve(cacheData.data)
			}
			//  如果不是因为请求接口被取消而报错误,那么就把接口从请求队列里清除
			if (error.code !== 'ERR_CANCELED' && error.config) {
				requestQueue.remove(error.config)
			}
			return Promise.reject(error)
		},
	)
}

export type postConfig = {
	inParams?: anyObject | anyArray
	outParamsType?: paramsType
	hardReload?: boolean
	cacheTime?: number
	config?: anyObject
}

//  接口请求前事件
type CallBackType = (config: anyObject) => anyObject
let beforeRequestCallBack: CallBackType
export const onBeforeRequest = (cd: CallBackType) => (beforeRequestCallBack = cd)

//  请求响应前
type BRCallBackType = (config: anyObject) => anyObject
let beforeResponseCallBack: BRCallBackType
export const onBeforeResponse = (cd: BRCallBackType) => (beforeResponseCallBack = cd)
//  请求正确响应事件
type CRCallBackType = (response: anyObject, next: (value: unknown) => void) => void
let correctResponseCallBack: CRCallBackType
export const onCorrectResponse = (cd: CRCallBackType) => (correctResponseCallBack = cd)

//  请求错误响应事件
type ERCallBackType = (error: anyObject, next: (reason?: unknown) => void) => void
let errorResponseCallBack: ERCallBackType
export const onErrorResponseCallBack = (cd: ERCallBackType) => (errorResponseCallBack = cd)

/** 封装post方法 */
export const post = (
	path: string,
	{
		inParams = {},
		outParamsType = {},
		//  请求接口默认开启缓存,请求正确响应,数据会存入内存,并进入缓存期,缓存期再次请求接口则读取内存数据
		//  开启后会从网络请求数据,并且会清空此接口的缓存,
		hardReload = false,
		//  接口数据的缓存时间,已秒为单位
		cacheTime = 15,
		config = {},
	}: postConfig,
): Promise<unknown> => {
	return new Promise((resolve, reject) => {
		service({ url: path, method: 'post', data: inParams || {}, config: { inParams, outParamsType, hardReload, cacheTime }, ...config } as AxiosRequestConfig)
			.then((response) => {
				if (correctResponseCallBack) correctResponseCallBack(response, resolve)
				else resolve(response)
			})
			.catch((error) => {
				if (errorResponseCallBack) errorResponseCallBack(error, reject)
				else reject(error)
			})
	})
}
/** 封装get方法 */
export const get = (
	path: string,
	{
		inParams = {},
		outParamsType = {},
		//  请求接口默认开启缓存,请求正确响应,数据会存入内存,并进入缓存期,缓存期再次请求接口则读取内存数据
		//  开启后会从网络请求数据,并且会清空此接口的缓存,
		hardReload = false,
		//  接口数据的缓存时间,已秒为单位
		cacheTime = 15,
		config = {},
	}: postConfig,
): Promise<unknown> => {
	return new Promise((resolve, reject) => {
		const query = Object.entries(inParams)
			.map((item) => item.join('='))
			.join('&')
		service({ url: path + `${Object.keys(inParams).length ? '?' : ''}` + query, method: 'get', data: query || {}, config: { inParams, outParamsType, hardReload, cacheTime }, ...config } as AxiosRequestConfig)
			.then((response) => {
				if (correctResponseCallBack) correctResponseCallBack(response, resolve)
				else resolve(response)
			})
			.catch((error) => {
				if (errorResponseCallBack) errorResponseCallBack(error, reject)
				else reject(error)
			})
	})
}
export const openProxy = () => {
	const url = {
		baseURL: service.defaults.baseURL,
		originalUrl: proxyUrl,
	}
	;(service.defaults.headers.post as anyObject).originalUrl = url.baseURL
	service.defaults.baseURL = url.originalUrl
	return url
}
export const closeProxy = () => {
	if ((service.defaults.headers.post as anyObject).originalUrl) {
		service.defaults.baseURL = (service.defaults.headers.post as anyObject).originalUrl
		;(service.defaults.headers.post as anyObject).originalUrl = ''
	}
}
