import { Howl, Howler } from 'howler'

export const audio = {
	gold_coin: new Howl({ src: [require('@/assets/audio/gold_coin.mp3')] }),
	guoguan: new Howl({ src: [require('@/assets/audio/guoguan.mp3')] }),
	puzzle_ok_2: new Howl({ src: [require('@/assets/audio/puzzle_ok_2.mp3')] }),
	puzzle_ok: new Howl({ src: [require('@/assets/audio/puzzle_ok.mp3')] }),
	shoucang: new Howl({ src: [require('@/assets/audio/shoucang.mp3')] }),
	surprise: new Howl({ src: [require('@/assets/audio/surprise.mp3')] }),
	thief_01: new Howl({ src: [require('@/assets/audio/thief_01.mp3')] }),
	thief_02: new Howl({ src: [require('@/assets/audio/thief_02.mp3')] }),
	thief_03: new Howl({ src: [require('@/assets/audio/thief_03.mp3')] }),
	thief_04: new Howl({ src: [require('@/assets/audio/thief_04.mp3')] }),
	thief_05: new Howl({ src: [require('@/assets/audio/thief_05.mp3')] }),
	thief_06: new Howl({ src: [require('@/assets/audio/thief_06.mp3')] }),
	thief_07: new Howl({ src: [require('@/assets/audio/thief_07.mp3')] }),
	thief_08: new Howl({ src: [require('@/assets/audio/thief_08.mp3')] }),
	thief_09: new Howl({ src: [require('@/assets/audio/thief_09.mp3')] }),
	thief_10: new Howl({ src: [require('@/assets/audio/thief_10.mp3')] }),
	windows_close: new Howl({ src: [require('@/assets/audio/windows_close.mp3')] }),
	windows_open: new Howl({ src: [require('@/assets/audio/windows_open.mp3')] }),
	level: new Howl({ src: [require('@/assets/audio/level.mp3')] }),
	bg1: new Howl({ loop: true, src: [require('@/assets/audio/bg1.mp3')] }),
}
