export default {
	ranking: {
		rank_list: require('@/assets/img/zh/rank_list.png'),
		more: require('@/assets/img/zh/more.png'),
	},
	treasures: {
		puzzle_info_plane: require('@/assets/img/zh/puzzle_info_plane.png'),
	},
	ui: {
		touxiang_bg: require('@/assets/img/zh/touxiang_bg.png'),
		map_bt: require('@/assets/img/zh/map_bt.png'),
		paiming_bt: require('@/assets/img/zh/paiming_bt.png'),
		radar_btn_1: require('@/assets/img/zh/radar_btn_1.png'),
		radar_btn_2: require('@/assets/img/zh/radar_btn_2.png'),
		bigger_btn_1: require('@/assets/img/zh/bigger_btn_1.png'),
		bigger_btn_2: require('@/assets/img/zh/bigger_btn_2.png'),
		hideGame_bt: require('@/assets/img/zh/hideGame_bt.png'),
		showGame_bt: require('@/assets/img/zh/showGame_bt.png'),
		fb_bt: require('@/assets/img/zh/fb_bt.png'),
	},
}
