import { Dialog, Toast } from 'vant'

export const uninstallSW = () => {
	if ('serviceWorker' in navigator) {
		console.log(`serviceWorker状态:`, navigator.serviceWorker.ready)
		navigator.serviceWorker.ready.then((registration) => {
			registration.unregister().then(function (isSuccess) {
				//  isSucces==ture,代表成功卸载
				if (isSuccess) {
					Dialog({
						message: () => (
							<>
								<div>ServiceWorker卸载完毕!</div>
							</>
						),
					})
				} else {
					Dialog({
						message: () => (
							<>
								<div>ServiceWorker无需卸载!</div>
							</>
						),
					})
				}
			})
		})
	} else {
		Dialog({ message: `浏览器不支持serviceWorker` })
	}
}
