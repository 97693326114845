import { APP_ENV, NODE_ENV } from '@core/common/env'
import { Toast } from 'vant'
import { deviceInfo } from './debug_modules/device-info'
import { normalReload } from './debug_modules/reload'
import { uninstallSW } from './debug_modules/uninstallSW'
import '@core/style/debug.scss'

//  调试需要的功能在这里注册,不支持异步注册
type ToolsQueue = Set<{ name: string; install: () => void; status?: boolean | null }>
let toolsQueue: ToolsQueue = new Set([
	{ name: '卸载SW', install: uninstallSW },
	{ name: '重载页面', install: normalReload },
	{ name: '设备信息', install: deviceInfo },
])
export const addToolsModule = (_toolsQueue: anyArray) => {
	toolsQueue = new Set([...toolsQueue, ..._toolsQueue])
}

window.__debug__ = {}

export const createDebugTools = () => {
	;[...toolsQueue].map((tool) => (window.__debug__[tool.name] = tool.install))

	let doubleHit = 0
	let timer = 0
	let tap = 9 //点击次数
	if (APP_ENV.dev || APP_ENV.test) tap = 3
	//  进入调试模式流程如下：先按${tap}下 -> 等待${tap*250}毫秒 -> 再按${tap}下  即可进入开发者模式
	const handleMouseDown = (e: MouseEvent) => {
		if (doubleHit < 990 && e.x < 100 && e.y > 100) doubleHit++
		if (doubleHit >= 990 && e.x > 200 && e.y > 100) doubleHit++
		if (doubleHit == 1 && Number(timer) == 0) timer = setTimeout(() => (timer = doubleHit = doubleHit == tap ? 990 : 0), tap * 250) as unknown as number
		if (doubleHit == 991 && Number(timer) != 0) timer = setTimeout(() => (timer = doubleHit = doubleHit == 990 + tap ? 990 + tap : 0), tap * 250) as unknown as number
		if (doubleHit == 990 + tap) {
			document.body.removeEventListener('mousedown', handleMouseDown)
			Toast.loading({
				message: '正在加载调试面板...',
				forbidClick: true,
				duration: 0,
				className: 'debug-toast',
				onOpened: () => {
					;(document.querySelector('.debug-toast') as HTMLElement).style.width = '160px'
					;(document.querySelector('.debug-toast .van-toast__text') as HTMLElement).style.opacity = '1'
					import(/* webpackChunkName: "vconsole" */ '@core/tools/vconsole').then((Module) => {
						Toast('正在加载调试面板...完毕!')
						Module.initTools(toolsQueue)
					})
				},
			})
		}
		console.log(doubleHit)
	}
	document.body.addEventListener('mousedown', handleMouseDown)
}
