import { addToolsModule, createDebugTools } from 'hbooster-core/global/debug'
import { firstContentfulPaint } from './debug_modules/FCP'
import { apiProxy } from './debug_modules/api-proxy'

//  调试需要的功能在这里注册,不支持异步注册
//  ↓↓↓↓↓===========================================================↓↓↓↓↓

addToolsModule([
	{ name: '首屏时间', install: firstContentfulPaint },
	{ name: '接口代理', install: apiProxy, status: false },
])

//  ↑↑↑↑↑===========================================================↑↑↑↑↑

//  测试包自动进入调试模式
//  正式包进入调试模式流程如下：先按9下 -> 等待两秒 -> 再按9下  即可进入开发者模式
createDebugTools()
