import { closeProxy, openProxy } from 'hbooster-core/common/net'
import { Dialog } from 'vant'

let toggle = false
let url: { baseURL: string | undefined; originalUrl: string; status?: string }

export const apiProxy = () => {
	toggle = !toggle
	if (toggle) {
		url = openProxy()
		url.status = '开启'
	} else {
		closeProxy()
		url.status = '关闭'
	}

	Dialog({
		message: () => (
			<div style="font-size: 12px;">
				<div>代理状态：{url.status}</div>
				<div>代理地址：{url.baseURL}</div>
				<div>原始地址：{url.originalUrl}</div>
			</div>
		),
	})
}
