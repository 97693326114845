import { App, DirectiveBinding, RendererElement, VNode } from 'vue'
import * as core from './core'

//  工具方法
export const titleCase = (str: string) => str.charAt(0).toUpperCase() + str.substring(1).toLowerCase()
export const isEmpty = (obj: anyObject) => Object.keys(obj).length === 0

//  解析条件
export const parseCondition = (binding: anyObject) => {
	const arg = (binding.arg || '').toLowerCase()
	const value = binding.value.toLowerCase()
	const name = titleCase(binding.name)
	return (core as anyObject)[arg + name](value)
}

//  判断是否符合条件
export const isConditionPassed = (name: string, Vue: App) => (el: RendererElement, binding: DirectiveBinding, vnode: VNode) => {
	console.log('isConditionPassed', name, el, binding, vnode)
	if (!binding.value) {
		return console.error('指令必须给定一个值.')
	}
	//  获取不到指令名字,这里自己添加一个指令名字
	Object.assign(binding, { name })
	const isValid = parseCondition(binding)
	if (isValid && !isEmpty(binding.modifiers)) {
		const _el = vnode.el as anyObject
		//  虚拟dom添加属性,针对组件props
		Object.assign(_el.__vueParentComponent.props, binding.modifiers)
		//  真实dom添加属性,针对原生属性
		Object.assign(el, binding.modifiers)
	}
}
