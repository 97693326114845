import { App } from 'vue'
import { setOptions, setRoles } from './core'
import { isConditionPassed } from './directive'

const registerDirectives = (Vue: App) => {
	Vue.directive('role', { mounted: isConditionPassed('role', Vue) })
	Vue.directive('directiveOptions', { mounted: isConditionPassed('directiveOptions', Vue) })
	Vue.directive('role-or-permission', { mounted: isConditionPassed('role-or-permission', Vue) })
}

export default {
	install: (Vue: App, options = {}) => {
		setOptions(options)
		registerDirectives(Vue)
	},
}
