import { createApp } from 'vue'

import { store, registerStoreModule } from '@/global/store'
import { directive } from '@/global/directive'
import guard from '@core/tools/vueuse/guard/index'
import { i18n } from './lang'
import StackKeepAlive from 'stack-keep-alive/dist/library.esm.js'
import App from './App.vue'

import '@/common/net'
import '@/global/debug'
import './registerServiceWorker'
import { openProxy, closeProxy } from 'hbooster-core/common/net'

const app = createApp(App).use(store).use(i18n).use(StackKeepAlive).use(directive).use(guard)
registerStoreModule()
app.mount('#app')
closeProxy()
