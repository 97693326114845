export default {
	ranking: {
		rank_list: require('@/assets/img/en/rank_list.png'),
		more: require('@/assets/img/en/more.png'),
	},
	treasures: {
		puzzle_info_plane: require('@/assets/img/en/puzzle_info_plane.png'),
	},
	customs: {
		map_Berlin: require('@/assets/img/en/map_Berlin.png'),
		map_Iceland: require('@/assets/img/en/map_Iceland.png'),
		map_NewYork: require('@/assets/img/en/map_NewYork.png'),
		map_Paris: require('@/assets/img/en/map_Paris.png'),
		map_Prague: require('@/assets/img/en/map_Prague.png'),
		map_Shanghai: require('@/assets/img/en/map_Shanghai.png'),
		map_Vatican: require('@/assets/img/en/map_Vatican.png'),
		map_Venice: require('@/assets/img/en/map_Venice.png'),
		map_Zurich: require('@/assets/img/en/map_Zurich.png'),
	},
	ui: {
		touxiang_bg: require('@/assets/img/en/touxiang_bg.png'),
		map_bt: require('@/assets/img/en/map_bt.png'),
		paiming_bt: require('@/assets/img/en/paiming_bt.png'),
		radar_btn_1: require('@/assets/img/en/radar_btn_1.png'),
		radar_btn_2: require('@/assets/img/en/radar_btn_2.png'),
		bigger_btn_1: require('@/assets/img/en/bigger_btn_1.png'),
		bigger_btn_2: require('@/assets/img/en/bigger_btn_2.png'),
		hideGame_bt: require('@/assets/img/en/hideGame_bt.png'),
		showGame_bt: require('@/assets/img/en/showGame_bt.png'),
		fb_bt: require('@/assets/img/en/fb_bt.png'),
	},
}
