import { App, ObjectDirective } from 'vue'
import copy from '@core/global/directive_modules/copy'
import debounce from '@core/global/directive_modules/debounce'
import lookat from '@core/global/directive_modules/lookat'
import ellipsis from '@core/global/directive_modules/ellipsis'

export type DirectiveQueue = Set<{ name: string; install: ObjectDirective }>
let directiveQueue: DirectiveQueue = new Set([
	{ name: 'copy', install: copy },
	{ name: 'debounce', install: debounce },
	{ name: 'lookat', install: lookat },
	{ name: 'lookat', install: lookat },
	{ name: 'ellipsis', install: ellipsis },
])
//  统一添加指令
export const addDirectiveModule = (_directiveQueue: anyArray) => {
	directiveQueue = new Set([...directiveQueue, ..._directiveQueue])
}

//  批量注册指令
export const createDirective = () => {
	return {
		install(Vue: App) {
			;[...directiveQueue].forEach((item) => Vue.directive(item.name, item.install))
		},
	}
}
