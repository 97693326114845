/* eslint-disable no-console */
import { register } from 'register-service-worker'

//  Service worker是根据package.json文件的name属性来区分应用,请确保name值的唯一性
//  文档:https://goo.gl/AFskqB
if (process.env.NODE_ENV === 'production') {
	register(`${process.env.BASE_URL}service-worker.js`, {
		ready(registration) {
			console.log('应用程序正在由Service worker从缓存中提供服务.')
		},
		registered() {
			console.log('Service worker已注册.')
		},
		cached() {
			console.log('内容已缓存以供脱机使用.')
		},
		updatefound() {
			console.log('正在下载新内容.')
		},
		updated() {
			console.log('新内容可用；请刷新.')
		},
		offline() {
			console.log('未找到网络连接。应用程序正在脱机模式下运行.')
		},
		error(error) {
			console.error('Service worker者注册期间出错:', error)
		},
	})
}
