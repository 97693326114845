import { defineStore } from 'pinia'
import { Flip } from 'number-flip'
import { nextTick, PropType } from 'vue'
import { createBiggerModel } from '@/common/sequence_frame'
import { Toast } from 'vant'
export const Krpano = defineStore('Krpano', {
	//persist: true,
	state: () => ({
		run: {} as anyObject,
	}),
	actions: {
		init() {
			this.run = document.querySelector('#krpanoSWFObject') as anyObject
		},
		zoomIn(num: number) {
			const { run } = this
			console.log(num)
			run.call(`
			if(control.usercontrol==all,
			stoptween(view.fov|view.hlookat|view.vlookat);
			set(fov_moveforce,${num});,
			set(fov_moveforce,0);
			);
		`)
		},
		effects(effectsName: string, value: string, time: number, tweentype: string | null, callback?: () => void) {
			const { run } = this
			const jscallString = ''
			run.call(`tween(plugin[zfilter].${effectsName},${value},${time},${tweentype},${jscallString});`)
		},
		addHotspot(name: string, option: anyObject) {
			const { run } = this
			run.call(`addhotspot(${name})`)
			for (const key in option) {
				run.set(`hotspot[${name}].${key}`, option[key])
			}
		},
		loadPanoXml(id: string, callback?: () => void) {
			const { run } = this
			const { krpanoObj } = window
			krpanoObj.__loadPanoXmlCB__ = callback
			run.call(`loadpano(%HTMLPATH%/xml/city/${id}.xml, ${id} = true,MERGE, LIGHTBLEND(0, 0xFFFFFF, 1.0, linear),
        set(events[loadingbar].onloadcomplete,jscall(krpanoObj.__loadPanoXmlCB__()))
      )`)
		},
		includexml(url: string, thisKrpano?: anyObject, loaddone?: () => void) {
			const { run } = this
			//const jscallString = `jscall(krpano_effectsTarget_callback();window.krpano.set('layer[${layerName}].sprite', null));`
			const jscallString = ``
			if (thisKrpano) {
				thisKrpano.call(`includexml(%HTMLPATH%/xml/${url},${jscallString})`)
			} else {
				run.call(`includexml(%HTMLPATH%/xml/${url},${jscallString})`)
			}

			//if (thiskrpano.get(`scene[${id}]`)) {
			//	thiskrpano.call(`loadscene(${id}, ${id} = true,MERGE, LIGHTBLEND(1, 0xFFFFFF, 1.0, linear))`)
			//} else {
			//	this.readXml(id, thiskrpano)
			//	thiskrpano.call(`loadscene(${id}, ${id} = true,MERGE, LIGHTBLEND(1, 0xFFFFFF, 1.0, linear))`)
			//}
		},
		createLineAndArrow(ath: number, atv: number, onclick?: () => void) {
			const { run } = this
			const xmlString = `
      <krpano>
      <layer name="line-shape" keep="false">
        <arrow>
          <point name="p0" x="0" y="3000" />
          <point name="p1" x="-5" y="0" />
          <point name="p2" x="5" y="0" />
          <center dist="180" />
        </arrow>
      </layer>
      <layer name="line-shape2" keep="false">
        <arrow>
          <point name="p0" x="0" y="60" />
          <point name="p1" x="-13" y="30" />
          <point name="p2" x="0" y="36" />
          <point name="p3" x="13" y="30" />
          <center dist="180" shadowdrop="0.35">
              <functionv>
                  <point name="p0" vlookat="0" posv="16" />
                  <point name="p1" vlookat="90" posv="90" />
              </functionv>
          </center>
        </arrow>
      </layer>
      <style name="myline" fillcolor="0x00d722" fillalpha="0.7" bordercolor="0x00d700" borderalpha="0" keep="false" handcursor="false" shadow="false" enabled="true" capture="true" zoom="true"/>
      <layer name="connections">
        <connection name="line" hdir="${ath}" vdir="${atv}" shape="line-shape" arrowstyle="myline" onclick="hotspot[portalEffects].triggerevent(onclick);" />
        <connection name="scene2" hdir="${ath}" vdir="${atv - 1}"  onclick="hotspot[portalEffects].triggerevent(onclick);" />
        </layer>
      </krpano>
      `
			run.call(`includexmlstring(${xmlString})`)
			setTimeout(() => {
				run.call(`tween(hotspot[line].alpha,0,3,default,set(hotspot[line].scale,0););`)
				run.call(`tween(hotspot[scene2].alpha,0,3,default,set(hotspot[scene2].scale,0););`)
			}, 10000)
		},
		//includexml(url, loaddone*)
	},
})
