import { serialize, deserialize } from '@core/tools/json-serialize'

type Options = {
	canPersistent?: boolean
	superRole?: string | null
}

export const options: anyObject = { canPersistent: false, superRole: null }
let roles: anyArray = []
let permissions: anyArray = []
let guard: { roles?: anyArray; permissions?: anyArray } = {}

//  许可配置
export const setOptions = (_options: Options) => {
	for (const [key, val] of Object.entries(_options)) {
		options[key] = val
	}
	if (options.canPersistent) {
		guard = deserialize('guard') || {}
		roles = guard.roles ? guard.roles : []
		permissions = guard.permissions ? guard.permissions : []
	}
}

//  设置相关权限
export const setRoles = (_roles: anyArray) => {
	console.log('_roles', guard, _roles)
	guard.roles = _roles
	roles = _roles
	if (options.canPersistent) {
		serialize('guard', { ...guard, roles })
	}
}
export const setPermissions = (_permissions: anyArray) => {
	guard.permissions = _permissions
	permissions = _permissions
	if (options.canPersistent) {
		serialize('guard', { ...guard, permissions })
	}
}

//  获取相关权限
export const getRoles = () => roles
export const getPermissions = () => permissions

//  超级权限
export const isSuperUser = () => options.superRole && roles.includes(options.superRole)

//  判断角色
export const hasRole = (role: string) => {
	return isSuperUser() || roles.includes(role)
}
export const unlessRole = (role: string) => !hasRole(role)
export const hassomeRole = (values: string) => {
	if (isSuperUser()) return true
	const roles = values.split('|')
	return roles.some((role) => hasRole(role))
}
export const haseveryRole = (values: string) => {
	if (isSuperUser()) return true
	const roles = values.split('|')
	return roles.every((role) => hasRole(role))
}

//  判断权限
export const hasPermission = (permission: string) => isSuperUser() || roles.includes(permission)
export const unlessPermission = (permission: string) => !hasPermission(permission)
export const hassomePermission = (values: string) => {
	if (isSuperUser()) return true
	const permissions = values.split('|')
	return permissions.some((permission) => hasPermission(permission))
}
export const haseveryPermission = (values: string) => {
	if (isSuperUser()) return true
	const permissions = values.split('|')
	return permissions.every((permission) => hasPermission(permission))
}
