import { addDirectiveModule, createDirective } from 'hbooster-core/global/directive'

//  指令在这里添加,不支持异步添加
//  ↓↓↓↓↓===========================================================↓↓↓↓↓

addDirectiveModule([])

//  ↑↑↑↑↑===========================================================↑↑↑↑↑

//  创建全局指令
export const directive = createDirective()
