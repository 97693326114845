import { post as p, createPost, onBeforeRequest, onCorrectResponse, onErrorResponseCallBack, setProxyUrl, onBeforeResponse } from 'hbooster-core/common/net'
import { baseUrl, proxyUrl } from './url'

setProxyUrl(proxyUrl)

createPost({
	baseURL: baseUrl,
	timeout: 6000,
	headers: {
		// 定义post请求的数据类型
		'Content-Type': 'application/json;',
		// 定义post响应的数据类型
		Accept: 'application/json, text/plain, */*',
	},
})

let token: string
export const setToken = (t: string) => {
	console.log('ttttttttt', t)
	token = t
}

//  接口请求前触发,可以在此处添加请求头等,比如token等
onBeforeRequest((config) => {
	config.headers.Authorization = 'Bearer ' + token
	return config
})

onBeforeResponse((config) => {
	console.log('config', config)
	//config.data = {
	//	data: config.data,
	//	message: '',
	//	code: 200,
	//}
	return config
})

//  接口正确响应后触发
onCorrectResponse((response, next) => {
	next(response)
})

//  接口错误响应后触发
onErrorResponseCallBack((response, next) => {
	next(response)
})
