import { DirectiveBinding, RendererElement, VNode } from 'vue'
const ellipsis = {
	beforeMount(el: RendererElement, binding: DirectiveBinding) {
		if (binding.value) {
			if (el.querySelector(binding.value)) {
				el.targetEl = el.querySelector(binding.value)
			} else {
				return console.warn(`v-ellipsis [${binding.value}]没有找到目标元素`)
			}
		} else {
			el.targetEl = el
		}
		el.targetEl.style.overflow = 'hidden'
		el.targetEl.style.textOverflow = 'ellipsis'
		el.targetEl.style.display = '-webkit-box'
		el.targetEl.style.webkitBoxOrient = 'vertical'
		el.targetEl.style.webkitLineClamp = binding.arg ? binding.arg : 1
		el.targetEl.style.wordBreak = 'break-all'
	},
	updated(el: RendererElement, binding: DirectiveBinding) {
		if (binding.value) {
			if (el.querySelector(binding.value)) {
				el.targetEl = el.querySelector(binding.value)
			} else {
				return console.warn(`v-ellipsis [${binding.value}]没有找到目标元素`)
			}
		} else {
			el.targetEl = el
		}
	},
}

export default ellipsis
