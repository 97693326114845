import { get, post, postConfig } from 'hbooster-core/common/net'
//  接口文档:http://www.apifox.cn/apidoc/shared-ab9c1274-94e7-4d80-8f58-285f8ab0c3e1  访问密码 : abc

/* ==========  USER  ========== */
//  获取我的信息
export const user_v1_me = (parmas: anyObject, config?: postConfig) => {
	return get('/user/v1/me', { inParams: parmas, ...config })
}

/* ==========  OAUTH  ========== */
//  facebook 登录
export const oauth_v1_facebook = (parmas: anyObject, config?: postConfig) => {
	return post('/oauth/v1/facebook', { inParams: parmas, ...config })
}
//  wechat 登录
export const oauth_v1_wechat = (parmas: anyObject, config?: postConfig) => {
	return post('/oauth/v1/wechat', { inParams: parmas, ...config })
}
//  获取测试token
export const oauth_v1_test = (parmas: anyObject, config?: postConfig) => {
	return post('/oauth/v1/test', { inParams: parmas, ...config })
}

/* ==========  GAME  ========== */
//  获取当前关卡用户的信息（已找到的点[id]数组，收集到的碎片信息）
export const game_v1_level = (parmas: anyObject, config?: postConfig) => {
	return get('/game/v1/level', { inParams: parmas, ...config })
}
//  获取当前用户地图中完成的拼图信息
export const game_v1_map_puzzle = (parmas: anyObject, config?: postConfig) => {
	return get('/game/v1/map/puzzle', { inParams: parmas, ...config })
}
//  获地图信息
export const game_v1_map_info = (parmas: anyObject, config?: postConfig) => {
	return get('/game/v1/map/info', { inParams: parmas, ...config })
}
//  碎片集满后，点击合成调用
export const game_v1_puzzle_composite = (parmas: anyObject, config?: postConfig) => {
	return post('/game/v1/puzzle/composite', { inParams: parmas, ...config })
}
//  查询排行榜
export const game_v1_rank = (parmas: anyObject, config?: postConfig) => {
	return get('/game/v1/rank', { inParams: parmas, ...config })
}
//  找到小偷后调用方法
export const game_v1_rank_v1_thief_found = (parmas: anyObject, config?: postConfig) => {
	return post('/game/v1/thief/found', { inParams: parmas, ...config })
}
//  游戏开始，页面打开时调用，设置计时起点
export const game_v1_start = (parmas: anyObject, config?: postConfig) => {
	return post('/game/v1/start', { inParams: parmas, ...config })
}
